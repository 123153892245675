@import url("https://cdn.jsdelivr.net/gh/orioncactus/pretendard@v1.3.5/dist/web/static/pretendard-dynamic-subset.css");

body {
  background-color: #ededed !important;
  font-family: Pretendard, -apple-system, BlinkMacSystemFont, system-ui, Roboto,
    "Helvetica Neue", "Segoe UI", "Apple SD Gothic Neo", "Noto Sans KR",
    "Malgun Gothic", "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
    sans-serif !important;
}

body::-webkit-scrollbar {
  width: 15px;
}

body::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 10px 10px #808080;
  border-radius: 15px;
  border: solid 3px transparent;
}

.content {
  max-width: 48rem;
  margin: 0 auto;
  padding: 3rem;
}

@media (max-width: 768px) {
  .content {
    padding: 10px;
  }
}

.loadingLogo {
  animation: loadingLogo 1.5s infinite ease-out;
}

@keyframes loadingLogo {
  0% {
    opacity: 0.1;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0.1;
  }
}
