.chatBox::-webkit-scrollbar {
  width: 15px;
}

.chatBox::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 10px 10px #808080;
  border-radius: 15px;
  border: solid 3px transparent;
}

@media (max-width: 768px) {
  .mfd {
    flex-direction: column;
  }

  .mmr {
    margin-right: auto;
  }
}
